import {gql} from '@apollo/client';
import {BuildQueryResult} from 'ra-data-graphql';

export const productProvider = (
  builtQuery: BuildQueryResult,
  fetchType: string,
): BuildQueryResult => {
  if (fetchType === 'GET_LIST') {
    return {
      ...builtQuery,
      query: gql`
        query allProducts(
          $page: Int!
          $perPage: Int!
          $filter: ProductFilterDto!
          $sortOrder: String!
          $sortField: String!
        ) {
          items: allProducts(
            page: $page
            perPage: $perPage
            filter: $filter
            sortField: $sortField
            sortOrder: $sortOrder
          ) {
            id
            partner {
              id
              name
              datamatrixPartnerId
              __typename
            }
            partnerId
            name
            active
            cbsMerchantId
            externalUrl
            createdAt
            updatedAt
            canExpire
            canBePartiallyPaid
            minimumPaymentInCents
            maximumPaymentInCents
            serviceFeeDescription
            datamatrixProductId
            allowedPaymentMethods
            category {
              id
              label
              code
              serviceOffer {
                id
                label
              }
              __typename
            }
            categoryId
            __typename
          }
          total: _allProductsMeta(
            page: $page
            perPage: $perPage
            filter: $filter
          ) {
            count
            __typename
          }
        }
      `,
    };
  }

  if (fetchType === 'GET_ONE') {
    return {
      ...builtQuery,
      query: gql`
        query Product($id: ID!) {
          data: Product(id: $id) {
            id
            active
            name
            cbsMerchantId
            externalUrl
            createdAt
            category {
              id
              label
              code
            }
            partner {
              id
              name
              datamatrixPartnerId
            }
            category {
              id
              label
              collectionType
              serviceOffer {
                label
              }
            }
            canBePaidByThirdParty
            datamatrixProductId
            serviceFeeDescription
            allowedPaymentMethods
            minimumPaymentInCents
            maximumPaymentInCents
            canExpire
            canBePartiallyPaid
            neptuneReceiptLogo
            apiUrl
            transactionNoticeUrl
            authenticationUrl
            hookUrlVersion
            authenticationMode
            credentialsUsername
            hasEncryptedCredentials
            oauthBody
            oauthBodyEncoding
          }
        }
      `,
    };
  }

  return builtQuery;
};
